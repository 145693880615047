import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { NavBar2 } from '../NavBar2';
import left from "../../images/leftdigit.png";
import right from "../../images/rightdigit.png";
import jodi from "../../images/jodi-digit.svg";

const DesawarGameplay: React.FC = () => {
  const param = useParams<{ id?: string }>(); // Adjusting to handle the parameter type
  console.log(param);

  const images = [
    {
      name: 'left_digit',
      src: left, // Corrected to directly reference the imported image
    },
    {
      name: 'right_digit',
      src: right, // Corrected to directly reference the imported image
    },
    {
      name: 'jodi_digit',
      src: jodi, // Corrected to directly reference the imported image
    },
  ];

  return (
    <>
      <NavBar2 isGaliDeswar={true} />
      <div className="container-fluid p-0 ">
        <div  className="row no-gutters justify-content-center ">
          {images.map((image, index) => (
            <div className="col-6 d-flex mt-3" key={index}>
              <Link to={`/betpage?id=${param.id}&name=${image.name}`}>
                <div className="image-container">
                  <img src={image.src} alt={`Desawar ${index + 1}`} className="img-fluid" />
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default DesawarGameplay;
