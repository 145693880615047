// import axios from 'axios';
// import React, { useState } from 'react';
// import { useLocation } from 'react-router-dom';
// import { toast } from 'react-toastify';
// import { NavBar2 } from '../NavBar2';

// const BettingInterface = () => {
//     const [jodiDigit, setJodiDigit] = useState('');
//     const [leftDigit, setLeftDigit] = useState('');
//     const [rightDigit, setRightDigit] = useState('');
//     const [amount, setAmount] = useState('');
//     const [jodiError, setJodiError] = useState('');
//     const { search } = useLocation();
//     const queryParams = new URLSearchParams(search);
  
//     const id = queryParams.get('id') || 'Unknown';
//     const name = queryParams.get('name') || 'Unknown';

//     const handleSubmit = async () => {
//         if (name === 'jodi_digit' && jodiDigit.length !== 2) {
//             setJodiError('Jodi Digit must be exactly 2 digits.');
//             return;
//         }
//         setJodiError(''); 

//         const formData = new FormData();
//         formData.append('game_id', id);
//         formData.append('game_type', name);
//         formData.append('session', 'Open');
//         formData.append('bid_points', amount);
//         formData.append('left_digit', leftDigit);
//         formData.append('right_digit', rightDigit);
//         formData.append('jodi_digit', jodiDigit);

//         try {
//             const response = await axios.post('https://development.smapidev.co.in/api/Api/gali_disawar_game', formData, {
//                 headers: {
//                     'Authorization': `Bearer YOUR_TOKEN_HERE`,  // Replace with your actual token
//                     'Content-Type': 'multipart/form-data',
//                 },
//             });

//             toast.success('Bid submitted successfully!', {
//                 position: "top-right",
//                 autoClose: 5000,
//                 hideProgressBar: false,
//                 closeOnClick: true,
//                 pauseOnHover: true,
//                 draggable: true,
//                 progress: undefined,
//             });

//             console.log('Response:', response.data);
//         } catch (error) {
//             toast.error('Failed to submit bid. Please try again.', {
//                 position: "top-right",
//                 autoClose: 5000,
//                 hideProgressBar: false,
//                 closeOnClick: true,
//                 pauseOnHover: true,
//                 draggable: true,
//                 progress: undefined,
//             });

//             console.error('Error:', error);
//         }
//     };

//     const handleJodiDigitChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//         const value = e.target.value;
//         if (/^\d{0,2}$/.test(value)) { // Allows only 2 digits
//             setJodiDigit(value);
//             if (value.length !== 2) {
//                 setJodiError('Jodi Digit must be exactly 2 digits.');
//             } else {
//                 setJodiError('');
//             }
//         }
//     };

//     const handleLeftDigitChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//         const value = e.target.value;
//         if (/^\d?$/.test(value)) { // Allows only 1 digit
//             setLeftDigit(value);
//         }
//     };

//     const handleRightDigitChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//         const value = e.target.value;
//         if (/^\d?$/.test(value)) { // Allows only 1 digit
//             setRightDigit(value);
//         }
//     };

//     return (
//         <>
//             <NavBar2 isGaliDeswar={true} />
//             <div className="container">
//                 <div className="card p-3">
//                     <div className="bgmatch card-header text-center text-white">
//                         <h4>{name}</h4>
//                         <p>{new Date().toDateString()}</p>
//                     </div>
                //     <div className="card-body">
                //         {name === 'jodi_digit' && (
                //             <div className="form-group text-start mt-4">
                //                 <label className='mb-2' htmlFor="jodiDigit">Enter Jodi Digit</label>
                //                 <input
                //                     type="text"
                //                     id="jodiDigit"
                //                     className="form-control"
                //                     value={jodiDigit}
                //                     onChange={handleJodiDigitChange}
                //                     placeholder="Enter Jodi Digit"
                //                     maxLength={2}
                //                 />
                //                 {jodiError && <div className="text-danger">{jodiError}</div>}
                //             </div>
                //         )}
                //         {name === 'left_digit' && (
                //             <div className="form-group text-start mt-4">
                //                 <label className='mb-2' htmlFor="leftDigit">Enter Left Digit</label>
                //                 <input
                //                     type="text"
                //                     id="leftDigit"
                //                     className="form-control"
                //                     value={leftDigit}
                //                     onChange={handleLeftDigitChange}
                //                     placeholder="Enter Left Digit"
                //                     maxLength={1}
                //                 />
                //             </div>
                //         )}
                //         {name === 'right_digit' && (
                //             <div className="form-group text-start mt-4">
                //                 <label className='mb-2' htmlFor="rightDigit">Enter Right Digit</label>
                //                 <input
                //                     type="text"
                //                     id="rightDigit"
                //                     className="form-control"
                //                     value={rightDigit}
                //                     onChange={handleRightDigitChange}
                //                     placeholder="Enter Right Digit"
                //                     maxLength={1}
                //                 />
                //             </div>
                //         )}
                //         <div className="form-group mt-3 text-start mt-4">
                //             <label className='mb-2' htmlFor="amount">Enter Amount</label>
                //             <input
                //                 type="number"
                //                 id="amount"
                //                 className="form-control"
                //                 value={amount}
                //                 onChange={(e) => setAmount(e.target.value)}
                //                 placeholder="Enter Amount"
                //             />
                //         </div>
                //     </div>
                // </div>

//                 <div className="mt-3 d-flex justify-content-between">
//                     <button
//                         className="btn btn-success p-2 w-100"
//                         onClick={handleSubmit}
//                     >
//                         Submit
//                     </button>
//                 </div>
//             </div>
//         </>
//     );
// };

// export default BettingInterface;






import axios from 'axios';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { NavBar2 } from '../NavBar2';

const BettingInterface = () => {
  const token = localStorage.getItem("token") || "";
    const [jodiDigit, setJodiDigit] = useState('');
    const [leftDigit, setLeftDigit] = useState('');
    const [rightDigit, setRightDigit] = useState('');
    const [amount, setAmount] = useState('');
    const [jodiError, setJodiError] = useState('');
    const [bids, setBids] = useState<any[]>([]); // Array to store multiple bids
    const { search } = useLocation();
    const queryParams = new URLSearchParams(search);

    const id = queryParams.get('id') || 'Unknown';
    const name = queryParams.get('name') || 'Unknown';

    const handleSubmit = async () => {
        if (name === 'jodi_digit' && jodiDigit.length !== 2) {
            setJodiError('Jodi Digit must be exactly 2 digits.');
            return;
        }
        setJodiError(''); 

        const formData = new FormData();
        formData.append('game_bids', JSON.stringify({ bids }));

        try {
            const response = await axios.post('https://development.smapidev.co.in/api/Api/gali_disawar_game', formData, {
                headers: {
                    token
                },
            });

            toast.success('Bids submitted successfully!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

            console.log('Response:', response.data);
            setBids([]); // Clear the bids after successful submission
        } catch (error) {
            toast.error('Failed to submit bids. Please try again.', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

            console.error('Error:', error);
        }
    };

    const handleJodiDigitChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (/^\d{0,2}$/.test(value)) { // Allows only 2 digits
            setJodiDigit(value);
            if (value.length !== 2) {
                setJodiError('Jodi Digit must be exactly 2 digits.');
            } else {
                setJodiError('');
            }
        }
    };

    const handleLeftDigitChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (/^\d?$/.test(value)) { // Allows only 1 digit
            setLeftDigit(value);
        }
    };

    const handleRightDigitChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (/^\d?$/.test(value)) { // Allows only 1 digit
            setRightDigit(value);
        }
    };

    const addBid = () => {
        // Validation check for required fields
        if (amount.trim() === '') {
            toast.error('Amount cannot be empty!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }
    
        if (name === 'jodi_digit' && jodiDigit.trim() === '') {
            toast.error('Jodi Digit cannot be empty!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }
    
        if (name === 'left_digit' && leftDigit.trim() === '') {
            toast.error('Left Digit cannot be empty!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }
    
        if (name === 'right_digit' && rightDigit.trim() === '') {
            toast.error('Right Digit cannot be empty!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }
    
        // Construct the bid object based on the digit type
        const bid = {
            game_id: id,
            game_type: name,
            session: 'Open',
            bid_points: amount,
            left_digit: name === 'left_digit' ? leftDigit : '',
            right_digit: name === 'right_digit' ? rightDigit : '',
            jodi_digit: name === 'jodi_digit' ? jodiDigit : '',
        };
    
        // Add the bid to the bids array
        setBids([...bids, bid]);
    
        // Clear the input fields
        setJodiDigit('');
        setLeftDigit('');
        setRightDigit('');
        setAmount('');
    };
    

    // Calculate total bid amount
    const totalBidAmount = bids.reduce((total, bid) => total + parseFloat(bid.bid_points), 0);

    return (
        <>
            <NavBar2 isGaliDeswar={true} />
            <div className="container">
                <div className="card p-3">
                    <div className="bgmatch card-header text-center text-white">
                        <h4>{name}</h4>
                        <p>{new Date().toDateString()}</p>
                    </div>
                    <div className="card-body">
                        {name === 'jodi_digit' && (
                            <div className="form-group text-start mt-4">
                                <label className='mb-2' htmlFor="jodiDigit">Enter Jodi Digit</label>
                                <input
                                    type="text"
                                    id="jodiDigit"
                                    className="form-control"
                                    value={jodiDigit}
                                    onChange={handleJodiDigitChange}
                                    placeholder="Enter Jodi Digit"
                                    maxLength={2}
                                />
                                {jodiError && <div className="text-danger">{jodiError}</div>}
                            </div>
                        )}
                        {name === 'left_digit' && (
                            <div className="form-group text-start mt-4">
                                <label className='mb-2' htmlFor="leftDigit">Enter Left Digit</label>
                                <input
                                    type="text"
                                    id="leftDigit"
                                    className="form-control"
                                    value={leftDigit}
                                    onChange={handleLeftDigitChange}
                                    placeholder="Enter Left Digit"
                                    maxLength={1}
                                />
                            </div>
                        )}
                        {name === 'right_digit' && (
                            <div className="form-group text-start mt-4">
                                <label className='mb-2' htmlFor="rightDigit">Enter Right Digit</label>
                                <input
                                    type="text"
                                    id="rightDigit"
                                    className="form-control"
                                    value={rightDigit}
                                    onChange={handleRightDigitChange}
                                    placeholder="Enter Right Digit"
                                    maxLength={1}
                                />
                            </div>
                        )}
                        <div className="form-group mt-3 text-start mt-4">
                            <label className='mb-2' htmlFor="amount">Enter Amount</label>
                            <input
                                type="number"
                                id="amount"
                                className="form-control"
                                value={amount}
                                onChange={(e) => setAmount(e.target.value)}
                                placeholder="Enter Amount"
                            />
                        </div>
                    </div>
                </div>

                <button
                    className="bgmatch p-2 w-100 text-white mt-3"
                    onClick={addBid}
                >
                    Add Bid
                </button>

                {/* Display added bids */}
                {bids.length > 0 && (
                    <div className="mt-4">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">Game Type</th>
                                    <th scope="col">Bid Points</th>
                                    {name === 'jodi_digit' && <th scope="col">Jodi Digit</th>}
                                    {name === 'left_digit' && <th scope="col">Left Digit</th>}
                                    {name === 'right_digit' && <th scope="col">Right Digit</th>}
                                </tr>
                            </thead>
                            <tbody>
                                {bids.map((bid, index) => (
                                    <tr key={index}>
                                        <td>{bid.game_type}</td>
                                        <td>{bid.bid_points}</td>
                                        {name === 'jodi_digit' && <td>{bid.jodi_digit}</td>}
                                        {name === 'left_digit' && <td>{bid.left_digit}</td>}
                                        {name === 'right_digit' && <td>{bid.right_digit}</td>}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                       
                    </div>
                )}

                {/* Display total bid amount and submit button in one row */}
                <div className="bgmatch mt-3 d-flex justify-content-between align-items-center text-white">
                    <h5>Total Bid Amount: {totalBidAmount}</h5>
                    <button
                        className="bgmatch p-2 text-white"
                        onClick={handleSubmit}
                    >
                        Submit All Bids
                    </button>
                </div>
            </div>
        </>
    );
};

export default BettingInterface;
