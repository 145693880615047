import React, { useEffect, useState } from "react";
import { NavBar2 } from "../NavBar2";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";

interface WinHistoryProps {
  data: {
    bid_points: string;
    bidded_at: string;
    digit: string;
    game_id: string;
    game_name: string;
    game_type: string;
    panna: string;
    win_points: string;
    won_at: string;
  }[];
}

export const StartWinHistory: React.FC = () => {
  const token = localStorage.getItem("token") || "";
  const todayDate = new Date().toISOString().split("T")[0];
  const [fromDate, setFromDate] = useState<string>(todayDate);
  const [toDate, setToDate] = useState<string>(todayDate);
  const [Data, setData] = useState<WinHistoryProps["data"]>([]);

  const handleFromDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFromDate(event.target.value);
  };

  const handleToDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setToDate(event.target.value);
  };

  const handleSubmit = async () => {
    try {
        const formattedFromDate = fromDate ? new Date(fromDate).toISOString().split('T')[0] : '';
        const formattedToDate = toDate ? new Date(toDate).toISOString().split('T')[0] : '';

        const response = await axios.post(
            "https://development.smapidev.co.in/api/Api/starline_win_history",
            {
                from_date: formattedFromDate,
                to_date: formattedToDate,
            },
            {
                headers: {
                    token
                }
            }
        );
        const responseData = response.data.data;

        // Filter the data on the frontend if necessary
        const filteredData = responseData.filter((item: any) => {
            const bidDate = new Date(item.bidded_at).toISOString().split('T')[0];
            return bidDate >= formattedFromDate && bidDate <= formattedToDate;
        });

        setData(filteredData);
        toast.success(response.data?.message);
    } catch (error) {
        toast.error("An error occurred");
    }
};


  useEffect(() => {
    handleSubmit();
  }, []);

  return (
    <>
      <NavBar2 isWinHistory={true} />
      <div className="container mx-auto p-2 rounded-lg text-white mt-4">
        <ToastContainer />
        <div className="login-primary px-4 py-4 rounded-4">
          <div className="flex justify-between items-center space-x-4">
            <h1 className="text-white">From Date</h1>
            <h1 className="text-white">To Date</h1>
          </div>

          <div className="flex justify-between">
            <input
              type="date"
              value={fromDate}
              onChange={handleFromDateChange}
              className="rounded-lg px-3 py-2 mt-2 mb-4 bg-gray-200 text-gray-800 focus:outline-none focus:ring-2 focus:ring-blue-400 ml-2"
            />

            <input
              type="date"
              value={toDate}
              onChange={handleToDateChange}
              className="rounded-lg px-3 py-2 mt-2 mb-4 bg-gray-200 text-gray-800 focus:outline-none focus:ring-2 focus:ring-blue-400 ml-4"
            />
          </div>

          <button
            onClick={handleSubmit}
            className="rounded mt-3 bg-blue-300 px-5 py-2 text-black"
          >
            SUBMIT REQUEST
          </button>
        </div>
      </div>

      <div className="container mt-4">
        {Data?.length > 0 ? (
          Data.map((item, index) => (
            <div className="rounded-lg shadow-lg p-4 mb-4 border border-gray-200" key={index}>
              <div className="text-center text-lg font-semibold">
                {item.game_name} ({item.game_type})
              </div>
              <hr className="thick-hr my-3 " />
              <div className="flex justify-between">
                <span className="font-semibold">#{item.digit}</span>
                <span className="font-semibold">Points: {item.bid_points}</span>
              </div>
              <div className="text-left mt-2 text-sm text-gray-600">
                Digit {item.digit}
              </div>
              <div className="text-right text-xs text-gray-500">
                {new Date(item.bidded_at).toLocaleString()}
              </div>
            </div>
          ))
        ) : (
          <p>No data available</p>
        )}
      </div>
    </>
  );
};

export default StartWinHistory;
