import React from 'react';
import { NavBar2 } from '../NavBar2';
import left from "../../images/single-digit (1).svg";
import right from "../../images/single-panna.png";
import jodi from "../../images/double-panna.png";
import jodi1 from "../../images/tripple-panna.svg";
import { Link, useParams } from 'react-router-dom';




const StarlineGameplay: React.FC = () => {
  const param=useParams()
  // Replace these with your actual image URLs or import them
  const images = [
    {
      name: 'single_digit',
      src: left, // Corrected to directly reference the imported image
    },
    {
      name: 'single_panna',
      src: right, // Corrected to directly reference the imported image
    },
    {
      name: 'doubble_panna',
      src: jodi, // Corrected to directly reference the imported image
    },
    {
      name: 'triple_panna',
      src: jodi1, // Corrected to directly reference the imported image
    },
  ];

  return (
    <>
    <NavBar2 isStarLine={true} />
    <div className="container mt-4">
      <div className="row">
      {images.map((image, index) => (
            <div className="col-6 d-flex mt-3" key={index}>
              <Link to={`/starbetpage?id=${param.id}&name=${image.name}`}>
                <div className="image-container">
                  <img src={image.src} alt={`Desawar ${index + 1}`} className="img-fluid" />
                </div>
              </Link>
            </div>
          ))}
      </div>
    </div>
    </>
  );
};

export default StarlineGameplay;
