import axios from 'axios';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { NavBar2 } from '../NavBar2';

const StarBettingInterface = () => {
  const token = localStorage.getItem("token") || "";
    const [doubblePanna, setDoubblePanna] = useState('');
    const [triplePanna, setTriplePanna] = useState('');
    const [leftDigit, setLeftDigit] = useState('');
    const [rightDigit, setRightDigit] = useState('');
    const [amount, setAmount] = useState('');
    const [jodiError, setJodiError] = useState('');
    const [bids, setBids] = useState<any[]>([]); // Array to store multiple bids
    const { search } = useLocation();
    const queryParams = new URLSearchParams(search);

    const id = queryParams.get('id') || 'Unknown';
    const name = queryParams.get('name') || 'Unknown';

    const handleSubmit = async () => {
        const formData = new FormData();
        formData.append('game_bids', JSON.stringify({ bids }));

        console.log(Array.from(formData.entries())); // Check the form data in the console

        try {
            const response = await axios.post('https://development.smapidev.co.in/api/Api/starline_place_bid', formData, {
                headers: {
                   token
                },
            });

            toast.success('Bids submitted successfully!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

            console.log('Response:', response.data);
            setBids([]); // Clear the bids after successful submission
        } catch (error) {
            toast.error('Failed to submit bids. Please try again.', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

            console.error('Error:', error);
        }
    };

    const addBid = () => {
        let digit = '';
    
        if (name === 'doubble_panna') {
            digit = doubblePanna;
        } else if (name === 'triple_panna') {
            digit = triplePanna;
        } else if (name === 'single_panna') {
            digit = leftDigit;
        } else if (name === 'single_digit') {
            digit = rightDigit;
        }
    
        // Validation checks
        if (amount.trim() === '') {
            toast.error('Amount cannot be empty!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }
    
        if (digit.trim() === '') {
            toast.error(`${name.replace('_', ' ')} cannot be empty!`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }
    
        const bid = {
            game_id: id,
            game_type: name,
            session: 'Open',
            bid_points: amount,
            digit: digit,
            panna: '', // Assuming panna is not used in this context
        };
    
        // Add the bid to the bids array
        setBids([...bids, bid]);
    
        // Reset form fields after adding bid
        setDoubblePanna('');
        setTriplePanna('');
        setLeftDigit('');
        setRightDigit('');
        setAmount('');
    };
    

    const handleDoubblePannaChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (/^\d{0,2}$/.test(value)) { // Allows only 2 digits
            setDoubblePanna(value);
            if (value.length !== 2) {
                setJodiError('Doubble Panna must be exactly 2 digits.');
            } else {
                setJodiError('');
            }
        }
    };

    const handleTriplePannaChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (/^\d{0,3}$/.test(value)) { // Allows only 3 digits
            setTriplePanna(value);
            if (value.length !== 3) {
                setJodiError('Triple Panna must be exactly 3 digits.');
            } else {
                setJodiError('');
            }
        }
    };

    const handleLeftDigitChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (/^\d?$/.test(value)) { // Allows only 1 digit
            setLeftDigit(value);
        }
    };

    const handleRightDigitChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (/^\d?$/.test(value)) { // Allows only 1 digit
            setRightDigit(value);
        }
    };

    // Calculate total bid amount
    const totalBidAmount = bids.reduce((total, bid) => total + parseFloat(bid.bid_points), 0);

    return (
        <>
            <NavBar2 isStarLine={true} />
            <div className="container">
                <div className="card p-3">
                    <div className="bgmatch card-header text-center text-white">
                        <h4>{name}</h4>
                        <p>{new Date().toDateString()}</p>
                    </div>
                    <div className="card-body">
                        {name === 'doubble_panna' && (
                            <div className="form-group text-start mt-4">
                                <label className='mb-2' htmlFor="doubblePanna">Enter Doubble Panna</label>
                                <input
                                    type="text"
                                    id="doubblePanna"
                                    className="form-control"
                                    value={doubblePanna}
                                    onChange={handleDoubblePannaChange}
                                    placeholder="Enter Doubble Panna"
                                    maxLength={2}
                                />
                                {jodiError && <div className="text-danger">{jodiError}</div>}
                            </div>
                        )}

                        {name === 'triple_panna' && (
                            <div className="form-group text-start mt-4">
                                <label className='mb-2' htmlFor="triplePanna">Enter Triple Panna</label>
                                <input
                                    type="text"
                                    id="triplePanna"
                                    className="form-control"
                                    value={triplePanna}
                                    onChange={handleTriplePannaChange}
                                    placeholder="Enter Triple Panna"
                                    maxLength={3}
                                />
                                {jodiError && <div className="text-danger">{jodiError}</div>}
                            </div>
                        )}

                        {name === 'single_panna' && (
                            <div className="form-group text-start mt-4">
                                <label className='mb-2' htmlFor="leftDigit">Enter Single Panna</label>
                                <input
                                    type="text"
                                    id="leftDigit"
                                    className="form-control"
                                    value={leftDigit}
                                    onChange={handleLeftDigitChange}
                                    placeholder="Enter Single Panna"
                                    maxLength={1}
                                />
                            </div>
                        )}

                        {name === 'single_digit' && (
                            <div className="form-group text-start mt-4">
                                <label className='mb-2' htmlFor="rightDigit">Enter Single Digit</label>
                                <input
                                    type="text"
                                    id="rightDigit"
                                    className="form-control"
                                    value={rightDigit}
                                    onChange={handleRightDigitChange}
                                    placeholder="Enter Single Digit"
                                    maxLength={1}
                                />
                            </div>
                        )}

                        <div className="form-group mt-3 text-start mt-4">
                            <label className='mb-2' htmlFor="amount">Enter Amount</label>
                            <input
                                type="number"
                                id="amount"
                                className="form-control"
                                value={amount}
                                onChange={(e) => setAmount(e.target.value)}
                                placeholder="Enter Amount"
                            />
                        </div>
                    </div>
                </div>

                <button
                    className="bgmatch p-2 w-100 text-white mt-3"
                    onClick={addBid}
                >
                    Add Bid
                </button>

                {/* Display added bids */}
                {bids.length > 0 && (

                <div className="mt-4">
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Game Type</th>
                                <th scope="col">Digit</th>
                                <th scope="col">Bid Points</th>
                            </tr>
                        </thead>
                        <tbody>
                            {bids.map((bid, index) => (
                                <tr key={index}>
                                    <td>{bid.game_type}</td>
                                    <td>{bid.digit}</td>
                                    <td>{bid.bid_points}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                )}


                {/* Display total bid amount and submit button in one row */}
                <div className="bgmatch mt-3 d-flex justify-content-between align-items-center text-white">
                    <h5>Total Bid Amount: {totalBidAmount}</h5>
                    <button
                        className="bgmatch p-2 text-white"
                        onClick={handleSubmit}
                    >
                        Submit All Bids
                    </button>
                </div>
            </div>
        </>
    );
};

export default StarBettingInterface;
